.cacib-components__multi-select {
  border: 1px solid var(--greyscale-500);
  border-radius: 4px;
  background-color: white;
  overflow: hidden;
  width: 100%;

  &__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--greyscale-500);

    &__search-icon {
      height: 20px;
      width: 20px;
    }

    &__search-input {
      width: 100%;
      height: 35px;

      input {
        border: unset !important;
        background-color: transparent !important;
      }
    }
  }

  &__available-items,
  &__selected-items {
    max-height: 300px;
    min-height: 300px;
    overflow: auto;
    padding: 8px 0;

    &__no-results {
      padding: 8px;
      cursor: default;

      span {
        color: var(--greyscale-600);
      }
    }

    &__item {
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;

      &__title {
        font-size: 12px;
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        color: var(--brand-tertiary);
      }

      &__description {
        color: var(--greyscale-600);
      }

      &__action {
        opacity: 0;
        margin-left: auto;
        height: 16px;
        width: 16px;

        path {
          fill: var(--fonctionnal-danger);
        }
      }

      &:hover & {
        &__action {
          height: 16px;
          width: 16px;
          margin-left: auto;
          opacity: 1;
        }
      }
    }
  }

  &__available-items {
    border-right: 1px solid var(--greyscale-500);
    background-color: rgb(255 0 0 / 4%);

    &__item {
      &__action {
        transform: rotate(45deg);

        path {
          fill: var(--brand-primary);
        }
      }
    }
  }

  &__selected-items {
    background-color: rgb(0 255 0 / 4%);

    &__selection-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-right: 5px;

      small {
        cursor: pointer;
      }
    }
  }

  &--disabled {
    background-color: transparent;
  }

  &--disabled & {
    &__available-items,
    &__selected-items {
      background-color: unset;

      &__item {
        cursor: default;
      }
    }
  }

  &--skeleton {
    cursor: default;
    background-color: var(--greyscale-100);
  }

  &--skeleton & {
    &__skeleton-items {
      &__item {
        margin-bottom: 10px;

        &__title {
          height: 25px;
          width: 100%;
          animation: flash 1s ease-in-out infinite;
          margin-bottom: 3px;
        }

        &__description {
          width: 50%;
          height: 15px;
          animation: flash 1s ease-in-out infinite;
        }
      }
    }
  }
}
