@import "../../layout/themes/components/bootstrap/css/bootstrap.css";
@import "../../layout/themes/css/gc-stater-package.css";
@import "../../layout/themes/css/apps-package.css";
@import "../../layout/themes/components/owl-carroussel/owl-carousel/owl.theme-package.css";
@import  "../../layout/themes/css/stratec.css";

.main-layout {
    margin-top: 49px;
    &__appname{
        margin-left: 10px;
        vertical-align: middle;
        color: #ffffff !important;
         padding-left: 1px; 
         padding-top: 7px
    }
    &__search-box {
        border-radius: 0px !important;
        margin-top: 0px !important; 
        font-size: 0.6em !important;
        width: 100% !important
    }

    &__sidebar{
        overflow-x: clip !important;
        background-color: #f1f1f1 !important;
        height: 100% !important;
    }

    &__form-control{
        &__select{
            color: #fff !important;
            background-color: transparent;
            font-size: 13px;
            option {
            color: #000000 !important;
            }
        } 
        &__input{
            color:#000000 !important
        }
    }

    .optionpage1_nav{
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .left-content-aapvs-close {
        .optionpage1_nav{
            display: flex;
            flex-direction: column !important;
            align-items: center;
        }
    }

    .mobile-menu {
        height: 70;
        overflow: hidden;
        position: fixed;
        z-index: 1000;
        bottom: 0;
        width: 105%;
        background-color: #ffffff;
        padding-bottom: 0;
        left: 0;
        margin-right: -9px;
        right: 0;
    }

    .btn:hover, .btn:active {
        background-color: #FFF !important;
        color: #000 !important;
        font-weight: bolder !important;
    }
    
}