.ant-table-content table {
    border-spacing: 0 15px !important;
}

thead.ant-table-thead tr th {
    background-color: #000 !important;
    color: #fff !important;
    text-align: center;
    font-size: 14px !important;
}

ul.ant-pagination {
    margin-bottom: 0 !important;
}

ul li.ant-pagination-item.ant-pagination-item-active a{
    font-size: 20px !important;
}
ul li.ant-pagination-item a{
    font-size: 15px !important;
}

.ant-picker-panels {
    background-color: #eff5e3;
}

label.ant-segmented-item.ant-segmented-item-selected {
    background-color: #000 !important;
    color: #fff !important;
} 

label.ant-segmented-item.ant-segmented-item-selected {
    color: #000  !important;
} 

label.ant-segmented-item.ant-segmented-item-selected .ant-segmented-item-label {
    color: #fff !important;
}